import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import RadioButtons from '../RadioButtons';
import { getValidation } from './validators';

export const RadioButtonsStacked = ({
  error,
  name,
  onBlur,
  onChange,
  options,
  value,
}) => {
  const { t } = useTranslation();

  const {
    hidden = false,
    isLargeText,
    overrideKey,
    radioItems,
    translationAttributes,
    withIcons,
    withSpaceBetween,
  } = options;

  useEffect(() => {
    if (hidden) {
      onChange(value);
    }
  }, [hidden, onChange, value]);

  if (hidden) {
    return null;
  }

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <RadioButtons
        isLargeText={isLargeText}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={radioItems}
        overrideKey={overrideKey}
        stacked={true}
        translationAttributes={translationAttributes}
        value={value}
        withIcons={withIcons}
        withSpaceBetween={withSpaceBetween}
      />
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

RadioButtonsStacked.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  options: PropTypes.shape({
    isLargeText: PropTypes.bool,
    radioItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    withSpaceBetween: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

RadioButtonsStacked.validation = getValidation;
