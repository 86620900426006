export const applicantBeneficiary = 'APPLICANT';

export const getOrderBeneficiaryValidation = ({
  errorTranslationKey,
  schema,
}) =>
  schema.test(
    'is-applicant-beneficiary',
    errorTranslationKey,
    value => value === applicantBeneficiary,
  );
