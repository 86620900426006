/* eslint-disable max-lines */
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Grid } from '@mui/material';
import { registerSubFormValidation } from '../../../../utils';
import {
  getCities,
  getPostCodes,
  getProvinces,
  getStreetDetails,
} from '../../../services/ItalianPostAddressService';
import { VisibilityContainer } from '../../VisibilityContainer';
import {
  ControlledChainedAutocomplete,
  ControlledChainedTextField,
} from './components';
import { AddressField } from './utils/constants';
import { mapFormValuesToAddressFields } from './utils/mapFormValuesToAddressFields';

const addressValidationFields = [
  { name: AddressField.province },
  { name: AddressField.city },
  { name: AddressField.zipCode },
  { name: AddressField.streetName },
  { name: AddressField.streetNumber },
];

// TODO eslint migration - move to separate file
function buildValidator({ name, optional }) {
  if (optional) {
    return yup.object.nullable();
  }

  if (name === AddressField.streetNumber) {
    return yup.object({
      value: yup
        .string()
        .nullable()
        .required(`fields.addressContainer.subFields.${name}.required`)
        .max(6, `fields.addressContainer.subFields.${name}.maxLength`),
    });
  }

  return yup.object({
    value: yup
      .string()
      .required(`fields.addressContainer.subFields.${name}.required`),
  });
}

function buildSchema(fields) {
  return Object.fromEntries(
    fields.map(field => [field.name, buildValidator(field)]),
  );
}

export const AddressContainerItalianPost = ({
  onChange,
  options = {
    disabledFields: [],
    hiddenFields: [],
    isStreetNumberNumericOnly: false,
    isStreetNameSelectionOnly: false,
    overrideKey: '',
    switchFieldsOrder: false,
  },
  submitAttempted,
  value,
}) => {
  const {
    disabledFields,
    hiddenFields,
    isStreetNameSelectionOnly,
    isStreetNumberNumericOnly,
    switchFieldsOrder,
  } = options;

  const shouldRenderField = fieldName =>
    !hiddenFields || !hiddenFields.includes(fieldName);

  const shouldDisableField = fieldName => disabledFields?.includes(fieldName);

  const fieldsToValidate = addressValidationFields.filter(item =>
    shouldRenderField(item),
  );

  const schema = yup.object().shape(buildSchema(fieldsToValidate));

  const { control, errors, getValues, trigger, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: value,
  });

  useEffect(() => {
    // Return the value as form functions - for use in the validator
    onChange({
      getValues: () => mapFormValuesToAddressFields(getValues()),
      hasErrors: () => Object.values(errors).length !== 0,
      isSubmitAttempted: () => submitAttempted,
      trigger,
    });
  }, [errors, getValues, onChange, submitAttempted, trigger]);

  return (
    <FormControl fullWidth={true}>
      <Box component="form" pt="0.725rem">
        <Grid container={true}>
          <VisibilityContainer
            isRendered={shouldRenderField(AddressField.province)}
          >
            <Grid item={true} xs={12}>
              <ControlledChainedAutocomplete
                control={control}
                disabled={shouldDisableField(AddressField.province)}
                errorKey={`fields.addressContainer.subFields.${AddressField.province}.required`}
                errors={errors}
                fetchItems={getProvinces}
                fetchOnInitialLoad={true}
                fetchOnUpstreamFilterChange={true}
                fullWidth={true}
                id={AddressField.province}
                labelKey={`fields.addressContainer.subFields.${AddressField.province}.label`}
                matchFromStart={true}
                name={AddressField.province}
                submitAttempted={submitAttempted}
              />
            </Grid>
          </VisibilityContainer>
          <VisibilityContainer
            isRendered={shouldRenderField(AddressField.city)}
          >
            <Grid item={true} mt={2} xs={12}>
              <ControlledChainedAutocomplete
                control={control}
                disabled={shouldDisableField(AddressField.city)}
                errorKey={`fields.addressContainer.subFields.${AddressField.city}.required`}
                errors={errors}
                fetchItems={getCities}
                fullWidth={true}
                id={AddressField.city}
                labelKey={`fields.addressContainer.subFields.${AddressField.city}.label`}
                name={AddressField.city}
                submitAttempted={submitAttempted}
                upstreamFilter={watch(AddressField.province)}
              />
            </Grid>
          </VisibilityContainer>
          <VisibilityContainer
            isRendered={shouldRenderField(AddressField.zipCode)}
          >
            <Grid item={true} mt={2} xs={12}>
              <ControlledChainedAutocomplete
                control={control}
                errorKey={`fields.addressContainer.subFields.${AddressField.zipCode}.required`}
                errors={errors}
                fetchItems={getPostCodes}
                fetchOnUpstreamFilterChange={true}
                fullWidth={true}
                id={AddressField.zipCode}
                inputType={'number'}
                labelKey={`fields.addressContainer.subFields.${AddressField.zipCode}.label`}
                name={AddressField.zipCode}
                submitAttempted={submitAttempted}
                upstreamFilter={watch(AddressField.city)}
              />
            </Grid>
          </VisibilityContainer>

          {switchFieldsOrder ? (
            <>
              <VisibilityContainer
                isRendered={shouldRenderField(AddressField.streetName)}
              >
                <Grid item={true} mt={2} xs={12}>
                  <ControlledChainedAutocomplete
                    control={control}
                    errorKey={`fields.addressContainer.subFields.${AddressField.streetName}.required`}
                    errors={errors}
                    fetchItems={getStreetDetails}
                    freeSolo={!isStreetNameSelectionOnly}
                    fullWidth={true}
                    id={AddressField.streetName}
                    labelKey={`fields.addressContainer.subFields.${AddressField.streetName}.label`}
                    name={AddressField.streetName}
                    submitAttempted={submitAttempted}
                    upstreamFilter={watch(AddressField.city)}
                  />
                </Grid>
              </VisibilityContainer>
              <VisibilityContainer
                isRendered={shouldRenderField(AddressField.streetNumber)}
              >
                <Grid item={true} mt={2} xs={12}>
                  <ControlledChainedTextField
                    control={control}
                    errors={errors}
                    freeSolo={true}
                    fullWidth={true}
                    id={AddressField.streetNumber}
                    name={AddressField.streetNumber}
                    submitAttempted={submitAttempted}
                    type={isStreetNumberNumericOnly ? 'number' : 'text'}
                    upstreamFilter={watch(AddressField.city)}
                  />
                </Grid>
              </VisibilityContainer>
            </>
          ) : (
            <>
              <VisibilityContainer
                isRendered={shouldRenderField(AddressField.streetNumber)}
              >
                <Grid item={true} mt={2} xs={12}>
                  <ControlledChainedTextField
                    control={control}
                    errors={errors}
                    freeSolo={true}
                    fullWidth={true}
                    id={AddressField.streetNumber}
                    name={AddressField.streetNumber}
                    submitAttempted={submitAttempted}
                    type={isStreetNumberNumericOnly ? 'number' : 'text'}
                    upstreamFilter={watch(AddressField.city)}
                  />
                </Grid>
              </VisibilityContainer>
              <VisibilityContainer
                isRendered={shouldRenderField(AddressField.streetName)}
              >
                <Grid item={true} mt={2} xs={12}>
                  <ControlledChainedAutocomplete
                    control={control}
                    errorKey={`fields.addressContainer.subFields.${AddressField.streetName}.required`}
                    errors={errors}
                    fetchItems={getStreetDetails}
                    freeSolo={!isStreetNameSelectionOnly}
                    fullWidth={true}
                    id={AddressField.streetName}
                    labelKey={`fields.addressContainer.subFields.${AddressField.streetName}.label`}
                    name={AddressField.streetName}
                    submitAttempted={submitAttempted}
                    upstreamFilter={watch(AddressField.city)}
                  />
                </Grid>
              </VisibilityContainer>
            </>
          )}
        </Grid>
      </Box>
    </FormControl>
  );
};

AddressContainerItalianPost.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    disabledFields: PropTypes.arrayOf(PropTypes.string),
    hiddenFields: PropTypes.arrayOf(PropTypes.string),
    isStreetNumberNumericOnly: PropTypes.bool,
    isStreetNameSelectionOnly: PropTypes.bool,
    overrideKey: PropTypes.string,
    switchFieldsOrder: PropTypes.bool,
  }),
  submitAttempted: PropTypes.bool,
  value: PropTypes.object,
};

export const validation = registerSubFormValidation('addressContainer');

AddressContainerItalianPost.validation = validation;
