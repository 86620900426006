import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Box,
} from '@material-ui/core';
import { styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../components/CopyBlock';
import { IconMapping } from '../../utils/enums';

const StyledIcon = styled('img')({
  marginBottom: '3rem',
  marginLeft: '1rem',
  marginRight: '1rem',
  width: '50px',
});

const useStyles = makeStyles(theme => ({
  group: {
    justifyContent: 'space-evenly',
    marginTop: '0.5rem',
  },
  radio: {
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    fontSize: props => (props.isLargeText ? '1rem' : '.875rem'),
    marginBottom: props => (props.withSpaceBetween ? '3rem' : '0'),
    marginRight: '0',
    '& .Mui-checked': {
      '& svg:nth-child(2)': {
        color: theme.palette.primary.main,
      },
    },
    '& > .MuiFormControlLabel-label': {
      fontSize: props => (props.isLargeText ? '1rem' : '.875rem !important'),
    },
  },
  label: {
    fontSize: props => (props.isLargeText ? '1rem' : '.875rem'),
    '&.Mui-focused, &.Mui-error': {
      color: '#333',
    },
  },
}));

export default function RadioButtons({
  isLargeText = false,
  name,
  onBlur,
  onChange,
  options,
  overrideKey,
  stacked = false,
  translationAttributes,
  value,
  withIcons,
  withSpaceBetween = false,
}) {
  const classes = useStyles({ isLargeText, withSpaceBetween, stacked });
  const { t } = useTranslation();
  const overrideLabel = overrideKey ? `${overrideKey}.` : '';

  return (
    <FormControl component="fieldset" fullWidth={true} size="small">
      <FormLabel className={classes.label} component="legend">
        {t(`fields.radioButtons.${name}.label`)}
      </FormLabel>
      <RadioGroup
        className={classes.group}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        row={!stacked}
        value={value}
      >
        {options.map(option => (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            key={option}
            width={'100%'}
          >
            <FormControlLabel
              checked={value === option}
              className={classes.radio}
              control={<Radio />}
              key={option}
              label={
                <CopyBlock
                  component={'span'}
                  i18nKey={`fields.radioButtons.${overrideLabel}${name}.options.${option}`}
                  value={translationAttributes}
                />
              }
              value={option}
            />
            {withIcons ? (
              <StyledIcon
                alt={IconMapping[option]?.altText}
                src={IconMapping[option]?.src}
              />
            ) : null}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  stacked: PropTypes.bool,
};

RadioButtons.defaultProps = {
  stacked: false,
};
