export default {
  get FATHOM_TOKEN() {
    return window.env?.FATHOM_TOKEN || process.env?.REACT_APP_FATHOM_TOKEN;
  },
  get GOOGLE_API_KEY() {
    return window.env?.GOOGLE_API_KEY || process.env?.REACT_APP_GOOGLE_API_KEY;
  },
  get GA_TOKEN() {
    return window.env?.GA_TOKEN || process.env?.REACT_APP_GA_TOKEN;
  },
  get METRICS_ORG_ID() {
    return window.env?.METRICS_ORG_ID || process.env?.REACT_APP_METRICS_ORG_ID;
  },
  get SENTRY_DSN() {
    return window.env?.SENTRY_DSN || process.env?.REACT_APP_SENTRY_DSN;
  },
  get SENTRY_ENV() {
    return window.env?.SENTRY_ENV || process.env?.REACT_APP_SENTRY_ENV;
  },
  get ENV() {
    return window.env?.ENV || process.env?.REACT_APP_ENV;
  },
  get SENTRY_RELEASE_VERSION() {
    return (
      window.env?.SENTRY_RELEASE_VERSION ||
      process.env?.REACT_APP_SENTRY_RELEASE_VERSION
    );
  },
  get ORIGINATION_SERVICE_URL() {
    return (
      window.env?.ORIGINATION_SERVICE_URL ||
      process.env?.REACT_APP_ORIGINATION_SERVICE_URL
    );
  },
};
