import { IRootState } from '../../store';
import {
  IAmount,
  IGetIsPaymentInfoValid,
  IMultiproductAggregate,
  IProductObject,
} from '../services/types';
import { getProductTotals } from './utils/getProductTotals';

export const getAmount = (state: IRootState) => state.instore.amount;

export const getAvailableProducts = (state: IRootState) =>
  state.instore.availableProducts ?? [];

export const getIsPricingStructureCodeRequired = (state: IRootState) =>
  state.instore.isPricingStructureCodeRequired;

export const getOrderAmount = (state: IRootState) => state.instore.amount;

export const getOrderDiscountAmount = (state: IRootState) =>
  state.instore.orderDiscountAmount;

export const getDepositOverrideAmount = (state: IRootState) =>
  state.instore.depositOverrideAmount;

export const getOrderProfile = (state: IRootState) =>
  state.instore.orderProfile;

export const getOrderProfiles = (state: IRootState) =>
  state.instore.orderProfiles;

export const getOrderProfileContractType = (state: IRootState) =>
  state.instore.orderProfile?.contractType;

export const getOrderProfileCountries = (state: IRootState) =>
  state.instore.orderProfile?.countries;

export const getOrderProfileUuid = (state: IRootState) =>
  state.instore.orderProfile?.uuid;

export const getPaymentSummary = (state: IRootState) =>
  state.instore.paymentSummary;

export const getPricingStructureCode = (state: IRootState) =>
  state.instore.pricingStructureCode;

export const getProductDetails = (state: IRootState) =>
  state.instore.productDetails;

export const getProducts = (state: IRootState) => state.instore.products;

export const getProductSku = (state: IRootState) => state.instore.productSku;

export const getPreviousOrders = (state: IRootState) =>
  state.instore.previousOrders;

export const getSelectedBillingCountry = (state: IRootState) =>
  state.instore.selectedBillingCountry;

export const getSelectedNumberOfInstallments = (state: IRootState) =>
  state.instore.selectedNumberOfInstallments;

export const getSelectedPreviousOrder = (state: IRootState) =>
  state.instore.selectedPreviousOrder;

export const getSelectedPreviousOrderDetails = (state: IRootState) =>
  state.instore.selectedPreviousOrderDetails;

export const getSelectedProductVariant = (state: IRootState) =>
  state.instore.selectedProductVariant;

export const getSelectedShipppingCountry = (state: IRootState) =>
  state.instore.selectedShippingCountry;

export const getIsPaymentInfoValid = (
  state: IRootState,
  totalAmount: IAmount,
  totalResidualValueAmount: IAmount,
) => {
  const hasEnteredAmount = totalAmount.amount || Number(totalAmount.amount) > 0;
  const hasSelectedTerms = getSelectedNumberOfInstallments(state)?.length;
  const hasValidPrice = totalAmount.amount > totalResidualValueAmount.amount;

  return (
    hasEnteredAmount &&
    getOrderProfileUuid(state) &&
    hasSelectedTerms &&
    hasValidPrice
  );
};

export const getPaymentInfo = (state: IRootState): IGetIsPaymentInfoValid => {
  const productAmount = getAmount(state);
  const selectedVariant = getSelectedProductVariant(state);
  const orderDiscountAmount = getOrderDiscountAmount(state);
  const depositOverrideAmount = getDepositOverrideAmount(state);

  const amount =
    productAmount && selectedVariant
      ? {
          amount: selectedVariant.price,
          currency: productAmount.currency,
        }
      : productAmount;

  const sku = getProductSku(state);
  const singleProductArray: IProductObject[] = [
    {
      discount: '0',
      price: amount.amount,
      quantity: 1,
      sku,
      product_variant_key: selectedVariant?.keyName,
      product_variant_type: selectedVariant?.type,
    },
  ];

  const multiProductAggregate: IMultiproductAggregate = getProductTotals(
    getProducts(state),
  );

  const totalAmount: IAmount = amount?.amount
    ? amount
    : multiProductAggregate?.totalAmount;

  const totalResidualValue: IAmount =
    multiProductAggregate?.totalResidualValue ?? {
      amount: '0',
      currency: null,
    };

  return {
    amount,
    sku,
    multiProductAggregate,
    orderDiscountAmount,
    depositOverrideAmount,
    singleProductArray,
    totalAmount,
    totalResidualValue,
  };
};

export const getAmountNormalised = (state: IRootState) => {
  const { amount, products } = state.instore;
  if (!products?.length) {
    return amount;
  }
  const { totalAmount } = getPaymentInfo(state);
  return totalAmount;
};
