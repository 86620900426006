import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export default function Information({ children, title }) {
  return (
    <Box component="dl" mb={1} mt={1}>
      <Box component="dt" fontWeight="bold">
        {title}
      </Box>
      <Box component="dd" ml={0} mt={0.2}>
        {children}
      </Box>
    </Box>
  );
}

Information.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
