import PropTypes from 'prop-types';
import {
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '@hooks';

import Information from '../../components/Information';
import { useFormatters } from '../../utils';

const useStyles = makeStyles({
  control: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    fontSize: '1rem',
    '& address': {
      fontStyle: 'normal',
    },
  },
  container: {
    marginRight: '0.5rem',
    marginTop: '0',
    marginBottom: '0.7rem',
  },
  table: {
    '& td, & th': {
      fontSize: '1rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      paddingTop: '0.1rem',
      paddingBottom: '0.1rem',
      border: 'none',
      '&:first-child': {
        width: '100%',
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    '& th': {
      fontWeight: 'bold',
    },
  },
});

function PaymentRow({ amount, currency, header, title }) {
  const component = header ? 'th' : 'td';

  return (
    <TableRow>
      <TableCell component={component}>{title}</TableCell>
      <TableCell component={component}>{currency}</TableCell>
      <TableCell align="right" component={component}>
        {amount}
      </TableCell>
    </TableRow>
  );
}

export default function OrderSummary({ value }) {
  const { t } = useTranslation();
  const { t: countryTranslation } = useTranslation('country');
  const classes = useStyles();

  const { formatAmountWithCurrency } = useFormatters();

  const {
    consumer: { address, email, name } = {},
    options: { showTotal },
    payment: { currency, payNow, total },
  } = value;

  const shouldDisplayPayNow = Number(payNow) > 0;

  return (
    <FormControl
      className={classes.control}
      component="fieldset"
      fullWidth={true}
      size="small"
    >
      <TableContainer className={classes.container}>
        <Table className={classes.table} size="small">
          <TableBody>
            {showTotal ? (
              <PaymentRow
                amount={formatAmountWithCurrency(total, currency)}
                title={t('fields.orderSummary.rows.total')}
              />
            ) : null}
            {shouldDisplayPayNow ? (
              <PaymentRow
                amount={formatAmountWithCurrency(payNow, currency)}
                header={true}
                title={t('fields.orderSummary.rows.payNow')}
              />
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>

      {name && (
        <Information title={name}>
          <address>{email}</address>
          <address>
            {address?.lines?.map(line => (
              <div key={line}>{line}</div>
            ))}
            {countryTranslation(address?.countryCode)}
          </address>
        </Information>
      )}
    </FormControl>
  );
}

OrderSummary.propTypes = {
  value: PropTypes.shape({
    options: PropTypes.shape().isRequired,
    payment: PropTypes.shape().isRequired,
    consumer: PropTypes.shape(),
  }).isRequired,
};

PaymentRow.propTypes = {
  amount: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currency: PropTypes.string,
  header: PropTypes.bool,
};

PaymentRow.defaultProps = {
  currency: '',
  header: false,
};
