import { useCallback, useMemo } from 'react';
import {
  Country,
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/mobile';
import {
  ISelectInputItem,
  SelectInput,
  TextInput,
} from '@heidi-pay/heidi-component-library/components';
import { styled, SelectChangeEvent } from '@mui/material';
import { useTranslation } from '@hooks';
import { generateCallingCodes } from '../utils/generateCallingCodes';

interface ICallingCodePickerProps {
  disabled: boolean;
  hasLabel?: boolean;
  labelOverride?: string;
  name: string;
  onChange: (value: Country | '') => void;
  value: Country | '';
}

const SelectContainer = styled('div')({ position: 'relative', width: '5.5em' });

const HiddenLabel = styled('label')({ display: 'none' });

const StyledValueSpan = styled('span')<{
  disabled: boolean;
}>(({ disabled, theme }) => ({
  paddingTop: '10px',
  paddingLeft: disabled ? '20px' : '15px',
  position: 'absolute',
  top: '4px',
  left: '3px',
  right: '35px',
  bottom: '3px',
  textAlign: 'left',
  backgroundColor: 'white',
  fontSize: '18px',
  lineHeight: '2rem',
  pointerEvents: 'none',
  color: theme.palette.black.main,
}));

export const CallingCodePicker = ({
  disabled = false,
  hasLabel = false,
  labelOverride,
  name,
  onChange,
  value,
}: ICallingCodePickerProps) => {
  const { i18n, t } = useTranslation();
  const { t: countryTranslation } = useTranslation('country');

  const countries = useMemo(
    () => generateCallingCodes(i18n.language, countryTranslation),
    [i18n.language, countryTranslation],
  );

  const label = t(
    `fields.mobileNumberInput.${
      labelOverride ? `${labelOverride}.` : ''
    }${name.replace('-select', '')}.countryCodeLabel`,
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      onChange(event.target.value as Country);
    },
    [onChange],
  );

  const getCallingCode = useCallback(
    (country: Country | '') => country && getCountryCallingCode(country),
    [],
  );

  const validCountries = useMemo(() => getCountries(), []);

  const isValidCountry = useCallback(
    (country: string): country is Country =>
      validCountries.includes(country as Country),
    [validCountries],
  );

  const selectItems: ISelectInputItem[] = useMemo(
    () =>
      countries.map(({ callingCode, code, name: countryName }) => ({
        value: code,
        text: `${countryName} (+${callingCode})`,
      })),
    [countries],
  );

  const onChangeCallingCode = useCallback(() => {}, []);

  return (
    <SelectContainer>
      <HiddenLabel htmlFor={name}>{label}</HiddenLabel>
      {!disabled && (
        <SelectInput
          disabled={disabled}
          id={name}
          name={name}
          onChange={handleChange}
          selectItems={selectItems}
          value={isValidCountry(value) ? value : ''}
        />
      )}
      {hasLabel && disabled ? (
        <TextInput
          disabled={true}
          label={label}
          onChange={onChangeCallingCode}
          value={value && `+${getCallingCode(value)}`}
        />
      ) : (
        <StyledValueSpan aria-hidden={true} disabled={disabled}>
          {value && `+${getCallingCode(value)}`}
        </StyledValueSpan>
      )}
    </SelectContainer>
  );
};
