interface ITranslationConstants {
  companyLegalName: string;
  companyName: string;
  italyInformativaUri: string;
  supportEmailAddress: string;
  swiss3DSecureUriDe: string;
  swiss3DSecureUriEn: string;
  swiss3DSecureUriFr: string;
  swiss3DSecureUriIt: string;
  swissPrivacyPolicyUriDe: string;
  swissPrivacyPolicyUriEn: string;
  swissPrivacyPolicyUriFr: string;
  swissPrivacyPolicyUriIt: string;
}

export const heyLightTranslationConstants: ITranslationConstants = {
  companyLegalName: 'HeyLight AG',
  companyName: 'HeyLight',
  italyInformativaUri:
    'https://storage.googleapis.com/mediobanca-public-docs/CO087FB_ON_LINE_09_24_HEYLIGHT.pdf',
  supportEmailAddress: 'heylight-support@heidipay.com',
  swiss3DSecureUriDe: 'https://www.heidipay.com/de-ch/3-d-secure/',
  swiss3DSecureUriEn: 'https://www.heidipay.com/en-ch/3-d-secure/',
  swiss3DSecureUriFr: 'https://www.heidipay.com/fr-ch/3-d-secure/',
  swiss3DSecureUriIt: 'https://www.heidipay.com/it-ch/3-d-secure/',
  swissPrivacyPolicyUriDe: 'https://heylight.com/ch/de/privacy-policy-ch',
  swissPrivacyPolicyUriEn: 'https://heylight.com/ch/en/privacy-policy-ch',
  swissPrivacyPolicyUriFr: 'https://heylight.com/ch/fr/privacy-policy-ch',
  swissPrivacyPolicyUriIt: 'https://heylight.com/ch/it/privacy-policy-ch',
};

export const getTranslationConstants = () => heyLightTranslationConstants;
