import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  IGenerateLabelParts,
  IGetTermsAutocompleteOptions,
  ITranslatePart,
} from './types';

const translatePart = ({ name, part, translate }: ITranslatePart) =>
  translate(`fields.orderProfilePicker.${name}.parts.${part}`);

export const getTermsAutocompleteOptions = ({
  terms,
  translate,
}: IGetTermsAutocompleteOptions) =>
  terms.map(term => {
    const translation = translate(
      'fields.orderProfilePicker.orderProfileTerms.options',
    );
    return { label: `${term} ${translation}`, value: term };
  });

const formatListOfInstallmentChoices = (
  choices: number[],
  translate: Translate,
): string => {
  if (choices.length === 1) {
    return choices[0].toString();
  }
  if (choices.length === 2) {
    const translatedOr = translate(
      'fields.orderProfilePicker.orderProfileDetails.parts.or',
    );

    return `${choices[0]} ${translatedOr} ${choices[1]}`;
  }
  return `${choices[0]}, ${formatListOfInstallmentChoices(
    choices.slice(1),
    translate,
  )}`;
};

export const getOrderProfilePickerLabel = ({
  availableTerms,
  labelPartsData: {
    depositPercentageFormatted,
    isManualAgreementSigning,
    name: opName,
    usesEqualPaymentsLabel,
  },
  name,
  translate,
}: IGenerateLabelParts) => {
  const parts = [];

  if (opName) {
    parts.push(`${opName} - `);
  }

  if (isManualAgreementSigning) {
    parts.push(
      `${translate('fields.selectPicker.instore.manualSignature')} - `,
    );
  }

  const formattedChoices = formatListOfInstallmentChoices(
    availableTerms,
    translate,
  );

  if (usesEqualPaymentsLabel) {
    parts.push(
      `${formattedChoices} `,
      translatePart({ name, part: 'equalPayments', translate }),
    );
  } else {
    parts.push(
      `${depositPercentageFormatted} `,
      translatePart({ name, part: 'upfront', translate }),
      ', ',
      `${formattedChoices} `,
      translatePart({ name, part: 'payments', translate }),
    );
  }

  return parts.join('');
};
