export const FieldType = Object.freeze({
  codiceFiscale: 'codiceFiscale',
  email: 'email',
  ibanNumber: 'ibanNumber',
});

export const InputType = Object.freeze({
  email: 'email',
  text: 'text',
});
