import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '@hooks';

import { changeLocale, getCurrentLocale, getLocales } from '@redux';
import { parseLocale } from '../../utils';

const useStyles = makeStyles({
  root: {
    '&$disabled': {
      color: '#989898',
    },
  },
  disabled: {},
});

function lang(locale) {
  // assume latin based langs for now
  return parseLocale(locale).lang.toUpperCase();
}

export default function LocalePicker() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locales = useSelector(getLocales);
  const currentLocale = useSelector(getCurrentLocale);
  const { t } = useTranslation();

  const change = locale => dispatch(changeLocale(locale));

  return (
    <ButtonGroup
      aria-label={t('footer.localePicker.label')}
      color="primary"
      size="small"
      variant="text"
    >
      {locales.map(locale => (
        <Button
          classes={{
            root: classes.root,
            disabled: classes.disabled,
          }}
          disabled={locale === currentLocale}
          key={locale}
          onClick={() => change(locale)}
        >
          {lang(locale)}
        </Button>
      ))}
    </ButtonGroup>
  );
}
