import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  TextField,
  Button,
  Box,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '@hooks';

import { verifyPassword } from '../../consumer/redux';

const useStyles = makeStyles({
  label: {
    marginBottom: '1rem',
  },
});

export default function LoginContainer({
  clearError,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  submit,
  value,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const { email } = options;

  const handleLogin = async event => {
    event.preventDefault();
    clearError();
    if (!value) {
      return setError('fields.loginContainer.required');
    }

    const isValid = await dispatch(verifyPassword(value));
    if (isValid) {
      return submit();
    }

    return setError('fields.loginContainer.invalid');
  };

  return (
    <>
      <FormControl component="fieldset" fullWidth={true}>
        <FormLabel className={classes.label} component="legend">
          {t('fields.loginContainer.label')}
        </FormLabel>

        <Box component="p" fontSize="1rem" mb={2} mt={1}>
          {email}
        </Box>

        <TextField
          error={!!error}
          helperText={t(error)}
          id={name}
          inputRef={inputRef}
          label={t('fields.loginContainer.placeholder')}
          name={name}
          onBlur={onBlur}
          onChange={event => onChange(event.target.value)}
          size="small"
          type="password"
          value={value}
          variant="outlined"
        />
      </FormControl>
      <Button
        color="primary"
        onClick={handleLogin}
        type="button"
        variant="contained"
      >
        {t('fields.loginContainer.button')}
      </Button>
    </>
  );
}

LoginContainer.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  clearError: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

LoginContainer.defaultProps = {
  value: '',
  inputRef: undefined,
};
