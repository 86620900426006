import * as yup from 'yup';
import { getOrderBeneficiaryValidation } from './getOrderBeneficiaryValidation';

export const CustomValidationType = {
  isApplicantBeneficiary: 'isApplicantBeneficiary',
};

const customValidationMap = {
  [CustomValidationType.isApplicantBeneficiary]: getOrderBeneficiaryValidation,
};

export const getValidation = (
  _,
  { customValidation: customValidationType, optional = false },
) => {
  let schema = yup.string();

  if (optional) {
    return schema.nullable();
  }

  schema = schema.required('fields.radioButtons.validation.required');

  const customValidationFunction = customValidationMap[customValidationType];
  if (customValidationFunction) {
    schema = customValidationFunction({
      errorTranslationKey: `fields.radioButtons.validation.${customValidationType}`,
      schema,
    });
  }

  return schema;
};
