let token = '';

export function getToken() {
  if (token) {
    return token;
  }
  try {
    if (window.sessionStorage) {
      return window.sessionStorage.getItem('sessionToken');
    }
  } catch {
    // Access was denied
    return '';
  }
  return '';
}

export function setToken(newToken) {
  token = newToken;
  if (window.sessionStorage) {
    try {
      window.sessionStorage.setItem('sessionToken', newToken);
    } catch {
      // Access was denied
    }
  }
}

export function hasToken() {
  return !!getToken();
}
