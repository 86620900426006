import {
  createTheme as createThemeV4,
  ThemeOptions as ThemeOptionsV4,
} from '@material-ui/core';
import {
  ThemeOptions as ThemeOptionsV5,
  createTheme as createThemeV5,
} from '@mui/material';

export const createThemes = (theme: ThemeOptionsV5) => {
  const themeV4 = createThemeV4(theme as ThemeOptionsV4);
  const themeV5 = createThemeV5(theme);
  return {
    themeV4,
    themeV5,
  };
};
