export const getFormValuesFormOrderDetails = orderDetails => {
  const {
    amount,
    customerDetails,
    orderDescription,
    orderReference,
    products,
    storeId,
  } = orderDetails;

  const {
    contactNumber: mobileNumberInstore,
    dateOfBirth,
    emailAddress: email,
    firstName,
    lastName,
  } = customerDetails || {};

  const {
    externalId: productIdentification,
    name,
    sku,
  } = products?.length > 0 ? products[0] : {};

  // Mobilezone custom form
  const isSingleProductForm = !!productIdentification;

  const commonFields = {
    dateOfBirth,
    email,
    firstName,
    lastName,
    mobileNumberInstore,
  };

  if (isSingleProductForm) {
    return {
      ...commonFields,
      instoreProductSearchTextInput: sku,
      merchant_order_id: orderReference,
      productIdentification,
      productName: name,
      totalPrice: amount,
    };
  }

  return {
    ...commonFields,
    merchantOrderId: orderReference,
    storeId,
    productDescription: orderDescription,
    orderAmount: amount,
  };
};
