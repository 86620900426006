import { createStyles } from '@material-ui/core/styles';
import { ColorCodes } from './enums';

export const legacyTheme = {
  palette: {
    primary: {
      main: '#52c3c2',
      light: '#757ce8',
      contrastText: '#fff',
    },
    secondary: {
      main: '#52c3c2',
    },
    background: {
      default: '#fff',
    },
    action: {
      disabledBackground: '#a1d6d6',
    },
    black: {
      main: '#000',
    },
    error: {
      contrastText: ColorCodes.ErrorContrastText,
      dark: ColorCodes.ErrorDark,
      light: ColorCodes.ErrorLight,
      main: ColorCodes.ErrorMain,
    },
    externalSpid: {
      contrastText: ColorCodes.ExternalSpidContrastText,
      dark: ColorCodes.ExternalSpidDark,
      main: ColorCodes.ExternalSpidMain,
    },
    inherit: {
      main: ColorCodes.Inherit,
    },
    legacyGrey: {
      main: ColorCodes.LegacyGreyMain,
      light: ColorCodes.LegacyGreyLight,
    },
    success: {
      contrastText: ColorCodes.SuccessContrastText,
      dark: ColorCodes.SuccessDark,
      light: ColorCodes.SuccessLight,
      main: ColorCodes.SuccessMain,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '4px',
          minHeight: '2.8rem',
          fontSize: '1rem',
        },
      },
    },
  },
  overrides: {
    transition: 'background-color 50ms,box-shadow 50ms,border 50ms;',
    MuiFormLabel: createStyles({
      root: {
        color: '#333',
        fontWeight: 'bold',
      },
    }),
    MuiInputLabel: createStyles({
      root: {
        color: '#aaa',
        fontWeight: 'normal',
      },
    }),
    MuiRadio: createStyles({
      root: {
        color: '#c4c4c4',
      },
    }),
    MuiButton: createStyles({
      root: {
        textTransform: 'none',
        borderRadius: '4px',
        minHeight: '2.8rem',
        fontSize: '1rem',
      },
      text: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&$disabled': {
          color: '#fff',
        },
      },
    }),
    MuiSvgIcon: createStyles({
      colorSecondary: {
        color: '#000',
      },
    }),
  },
  variables: {
    borderRadius: '4px',
  },
};

export const getLegacyTheme = (primaryColour?: string) => {
  if (primaryColour) {
    const newThemeConfig = JSON.parse(JSON.stringify(legacyTheme));
    newThemeConfig.palette.primary.main = primaryColour;
    newThemeConfig.palette.secondary.main = primaryColour;
    return newThemeConfig;
  }

  return legacyTheme;
};
