import PropTypes from 'prop-types';
import { FormControl, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxHeight: '100px',
    marginTop: '10px',
  },
  imageBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  image: {
    display: 'block',
    maxHeight: '100%',
    maxWidth: '100%',
    objectPosition: 'center',
  },
});

export default function ImageRow({ value }) {
  const { images = [] } = value;
  const classes = useStyles();

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Box className={classes.box}>
        {images.map(({ alt, url }) => (
          <div className={classes.imageBox} key={url}>
            <img alt={alt ?? ''} className={classes.image} src={url} />
          </div>
        ))}
      </Box>
    </FormControl>
  );
}

ImageRow.propTypes = {
  value: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
    ),
  }).isRequired,
};
