import { Panel } from '@heidi-pay/heidi-component-library/components';
import { Box, styled } from '@mui/material';
import { Children } from '@appTypes';
import { useGetHeaderOptions } from '../hooks';

interface IContentWrapper {
  children: Children;
}

const ContinueBtnWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 24px',
  '& button': {
    width: '100%',
  },
});

export const ContentWrapper = ({ children }: IContentWrapper) => {
  const {
    abandonVisible,
    backToMerchant,
    cancelUrl,
    isRedirectToMerchantDisabled,
  } = useGetHeaderOptions();

  const showButton = Boolean(cancelUrl) && abandonVisible;

  return (
    <>
      <Panel
        closeDisabled={isRedirectToMerchantDisabled}
        maxContentHeight="100%"
        minContentHeight="150px"
        onClose={showButton ? backToMerchant : undefined}
        width="100%"
      >
        {children}
      </Panel>
      <ContinueBtnWrapper id="portal-continue-btn" />
    </>
  );
};
