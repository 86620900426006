import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import ChainedAutocomplete from './ChainedAutocomplete';

export const ControlledChainedAutocomplete = ({
  control,
  disabled = false,
  errorKey,
  errors = {},
  fetchItems,
  fetchOnInitialLoad = false,
  fetchOnUpstreamFilterChange = false,
  freeSolo = false,
  id,
  inputType,
  labelKey,
  matchFromStart = false,
  name,
  submitAttempted = false,
  upstreamFilter = null,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ onBlur, onChange, ref, value }, { isTouched }) => (
      <ChainedAutocomplete
        disabled={disabled}
        error={isTouched || submitAttempted ? !!errors[name]?.message : false}
        errorKey={errorKey}
        fetchItems={fetchItems}
        fetchOnInitialLoad={fetchOnInitialLoad}
        fetchOnUpstreamFilterChange={fetchOnUpstreamFilterChange}
        freeSolo={freeSolo}
        id={id}
        inputType={inputType}
        labelKey={labelKey}
        matchFromStart={matchFromStart}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
        upstreamFilter={upstreamFilter}
        value={value}
      />
    )}
  />
);

ControlledChainedAutocomplete.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  errorKey: PropTypes.string,
  errors: PropTypes.object,
  fetchItems: PropTypes.func.isRequired,
  fetchOnInitialLoad: PropTypes.bool,
  fetchOnUpstreamFilterChange: PropTypes.bool,
  freeSolo: PropTypes.bool,
  id: PropTypes.string,
  inputType: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  matchFromStart: PropTypes.bool,
  name: PropTypes.string.isRequired,
  submitAttempted: PropTypes.bool,
  upstreamFilter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
};
