import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  FormControl,
  Autocomplete,
  Box,
  TextField,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { ChainedAutocompleteIcon } from '../AddressContainerItalianPost/components/ChainedAutocompleteIcon';
import { sortAndTranslateItemsBackwardCompatible } from './utils/SelectPickerUtil';

export const SelectPickerSearchable = ({
  autoComplete,
  displayRawValues,
  error,
  freeSolo,
  isLoading,
  label,
  name,
  onBlur,
  onChange,
  onInputChange,
  options,
  sx,
  value,
}) => {
  const parsedValue = value ?? '';
  const { disabled, withStackedLabel } = options;

  const { t } = useTranslation();
  const displayLabel = t(
    label ?? `fields.selectPickerSearchable.${name}.label`,
  );

  const selectItems = displayRawValues
    ? options.items
    : sortAndTranslateItemsBackwardCompatible(options, name, t);

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem', ...sx }}
      variant="outlined"
    >
      <Autocomplete
        autoComplete={autoComplete}
        disabled={disabled}
        forcePopupIcon={false}
        freeSolo={freeSolo}
        fullWidth={true}
        getOptionLabel={option => option.text}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={(event, newValue, reason) => {
          const syntheticEvent = {
            ...event,
            target: {
              ...event.target,
              name,
              value: newValue ? newValue.value : '',
            },
            reason,
          };
          onChange(syntheticEvent);
        }}
        options={selectItems}
        renderInput={params => (
          <TextField
            {...params}
            error={!!error && !disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Box sx={{ paddingBottom: '13px', paddingRight: '10px' }}>
                  <ChainedAutocompleteIcon
                    hasError={!!error && !isLoading}
                    isComplete={!!value && !error}
                    isDisabled={disabled}
                    isLoading={isLoading}
                  />
                </Box>
              ),
            }}
            label={displayLabel}
            onChange={event => onInputChange?.(event)}
            sx={{
              '> .MuiInputBase-root.MuiInputBase-adornedEnd': {
                padding: '20px 4px 2.5px 7px',
              },
            }}
            withStackedLabel={withStackedLabel}
          />
        )}
        size="small"
        value={
          selectItems.find(item => item.value === parsedValue) ?? {
            value: parsedValue,
            text: parsedValue,
          }
        }
      />
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

SelectPickerSearchable.propTypes = {
  autoComplete: PropTypes.bool,
  complete: PropTypes.bool,
  displayRawValues: PropTypes.bool,
  error: PropTypes.string,
  freeSolo: PropTypes.bool,
  inputRef: PropTypes.shape(),
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  options: PropTypes.shape().isRequired,
  value: PropTypes.string.isRequired,
};

SelectPickerSearchable.defaultProps = {
  autoComplete: false,
  complete: false,
  displayRawValues: false,
  error: '',
  freeSolo: false,
  isLoading: false,
  inputRef: undefined,
  label: null,
};

SelectPickerSearchable.validation = () =>
  yup.string().required('fields.selectPicker.required');
